// Hinges
// Gets the available hinges options for the selected size
// @params size [Integer] Size (Heigth * Width) of the door
// @params height [Integer] Height of the door
// @params width [Integer] Width of the door
// @returns [Array<Hash>] with the available options
const hinges = (size, height, width) => {
  let result = [
    { label: "Bisagras 35 mm", 
      value: "35MM_HINGE", 
      disabled: (width > 2400 || height > 2400) },
    { label: "Bisagra omnia", 
      value: "OMNIA_HINGE", 
      disabled: (width > 2400 || size > 2900 || height > 2400) },
    {
      label: "Bisagra omnia con Blumotion",
      value: "BLUEMOTION_HINGE",
      disabled: (width > 2400 || size > 2900 || height > 2400) },
    { label: "Sin mecanizado", 
      value: "NO_MECHANIZED", 
      disabled: (width > 2400 || height > 2400) }
  ]
  return result
};

const sliders = [
  { label: "Corredera RP50", value: "SLIDER_RP50", disabled: false },
];

const aventos = [
  { label: "HF superior", value: "TOP_HF", disabled: false },
  { label: "HF inferior", value: "BOTTOM_HF", disabled: false },
];

const options = [
  { label: "Sin mecanizado", value: "NO_MECHANIZED", disabled: false },
];

const showOption = (array, option, show) => {
  const optToDisable = array.findIndex(
    (optionToFind) => optionToFind.value === option
  );
  let opt = array[optToDisable]
  opt.disabled = !show 
  array[optToDisable] = opt
  return array;
};

const getOptions = (direction, size, height, width) => {
  let resultOptions = [];
  switch (direction) {
    case "Left":
    case "Right":
    case "Top":
    case "Bottom":
      resultOptions = resultOptions.concat(hinges(size, height, width));
      break;
    case "Avento":
      resultOptions = resultOptions.concat(aventos);
      break;
    case "Slider":
      if (size > 2600) return showOption(sliders, "SLIDER_RP50", false);
      return showOption(sliders, "SLIDER_RP50", true);
    default:
      return options;
  }
  return resultOptions;
};

const getMechanized = (mechanized) => {
  let option = ""
  let allOptions = [...hinges(0,0), ...aventos, ...sliders]
  option = allOptions.find(mechanizedOption => mechanizedOption.value === mechanized ) 
  return option 
}

export { options, getOptions, getMechanized };
