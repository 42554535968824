import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams, Route } from "react-router-dom";
import BorderProfile from "./BorderProfile";
import "./Profile.scss";
import { getAllProfile, getMaterial } from "../../api/APIGet";
import { connect } from "react-redux";
import { sizeRestrictions, isRenderSash } from "../../api/restrictionSash";
import BottomButtons from "../../components/bottomButtons";
import Preview from "../preview/Preview";

const Profile = ({ nameColor, dimensions, line, globalProfile, setGlobalProfile, setSelectedProfileSku, setRelleno,  ...props }) => {
  const [profiles, setProfiles] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { mechanized } = useParams();
  let { open } = props.match.params;
  console.log("open =====>", open)
  let PreviewContainer = (props) => {
    let { type } = props.match.params;
    let name = type === "normal" ? "puerta" : "verdulero";
    return (
      <Row className='justify-content-center'>
        <h4 className='bg-primary mt-3 preview-title  text-white text-center'>
          Esquema de tu {name}
        </h4>
        <Col className='col-lg-12 col-12 py-5 previewBack'>
          <Preview {...props} />
        </Col>
        <Col className='col-12'>
          <h6 className='mt-3 preview-leyend'>*Imagen referencial</h6>
        </Col>
      </Row>
    );
  };

  const getProfiles = async () => {
  setLoading(true);
  try {
    const response = await getAllProfile();
    setLoading(false);
    console.log("all Profiles", response);

    const profiles = filterByColor(response);
    let filteredProfiles = [...profiles];

    if (open === 'Slider') {
      if (props?.activeSku === 'negro') {
        filteredProfiles = filterOutProfiles(filteredProfiles, ["PERFIL OMNIA NEGRO", "PERFIL MINI NEGRO"]);
      } else if (props?.activeSku === 'silver') {
        filteredProfiles = filterOutProfiles(filteredProfiles, ["PERFIL OMNIA SILVER", "PERFIL MINI SILVER"]);
      } else if (props?.activeSku === 'champagne') {
        filteredProfiles = filterOutProfiles(filteredProfiles, ["PERFIL MINI CHAMPAGNE"]);
      }
    }

    if (open === 'Left'|| open === 'Right') {
      if (dimensions.height > 1300) {
        if (props?.activeSku === 'champagne') {
          filteredProfiles = filterOutProfiles(filteredProfiles,["PERFIL MINI CHAMPAGNE"]);
        }else if (props?.activeSku ==='negro'){
          filteredProfiles = filterOutProfiles(filteredProfiles,["PERFIL MINI NEGRO"]);
        } else if (props?.activeSku ==='silver'){
          filteredProfiles = filterOutProfiles(filteredProfiles,["PERFIL MINI SILVER"]);
        } 
      }  
    }
    if (open === 'Bottom'|| open === 'Top'|| open === 'Avento') {
      if (dimensions.width > 1300) {
        if (props?.activeSku === 'champagne') {
          filteredProfiles = filterOutProfiles(filteredProfiles,["PERFIL MINI CHAMPAGNE"]);
        }else if (props?.activeSku ==='negro'){
          filteredProfiles = filterOutProfiles(filteredProfiles,["PERFIL MINI NEGRO"]);
        } else if (props?.activeSku ==='silver'){
          filteredProfiles = filterOutProfiles(filteredProfiles,["PERFIL MINI SILVER"]);
        } 
      }  
    }

    setProfiles(filteredProfiles);
    console.log('Profiles =====>', filteredProfiles);
  } catch (error) {
    setLoading(false);
    // Handle error
    console.error('Error fetching profiles:', error);
  }
};

const filterOutProfiles = (profiles, exclusionKeywords) => {
  return profiles.filter((profile) => {
    return !exclusionKeywords.some((keyword) => profile.attributes.description.toUpperCase().includes(keyword));
  });
};

  useEffect(() => {
    let { resetSash } = props;
    resetSash();
    getProfiles()
  }, [props.activeSku, dimensions?.width, dimensions?.height]);

  const filterByColor = (profiles) => {
  const { width, height } = dimensions;
  const base_door = parseInt(width);
  const height_door = parseInt(height);
  const dimension = height_door + base_door;

  return profiles.filter((profile) => {
    const [, PROFILE, COLOR, , SIZE] = profile.attributes?.description?.split(" ");
    //const type_value = (COLOR === "CHAMPAGNE") ? parseInt(SIZE) : sizeRestrictions[PROFILE];
    const type_value = sizeRestrictions[PROFILE];

    return (
      profile.attributes.description.toUpperCase().includes(props?.activeSku?.toUpperCase()) &&
      dimension <= type_value
    );
  });
};


  console.log("profile color" , props?.activeSku)

  
  // const restrictionToSash = (description, child) => {
  //   let { width, height } = dimensions;
  //   let base_door = parseInt(width);
  //   let height_door = parseInt(height);
  //   let dimention = height_door + base_door;

  //   const [,profile,color,,size] = description
  //   // console.log('Profile, Color, Size ====>', profile, color, size)

  //   let type_value
  //   if (color === "CHAMPAGNE") {
  //     type_value = parseInt(size)
  //   } else {
  //     type_value = sizeRestrictions[profile];
  //   }
  //   // console.log('Type Value ===>', type_value)
  //   let array_line = line?.split(/[\s-]+/);
  //   let name_line = array_line?.[array_line?.length - 1];
  //   //console.log('Profile ', line)

  //   const greengrocerLine = array_line?.find(
  //     (line) => line.trim() === "VERDULERA"
  //   );
  //   if (dimention <= type_value) {
  //     if (greengrocerLine) {
  //       return isRenderSash(
  //         "VERDULERA",
  //         fillingSelected.attributes?.gloss,
  //         description,
  //         mechanized
  //       );
  //     }
  //     return isRenderSash(
  //       name_line,
  //       fillingSelected.attributes?.gloss,
  //       description,
  //       mechanized
  //     );
  //   } else {
  //     return false;
  //   }
  // };

  // let { sash, match } = props;
  // let { sku } = sash;

  console.log("sash =====>", props?.sash)


  const renderProfiles = () => {
    return profiles.map((profile, key) => {
      const photos = profile.attributes.photos.filter((val) => {
        return val.usage === "FotoTecnica";
      });
      if (photos) {
        let description = profile.attributes.description.split(/[\s-]+/);
        if (!photos[0] || !photos[1] || !photos[2]) { return };
        
          return (
            <BorderProfile
              id={profile.id}
              color={photos[0].url}
              previewPhoto={photos[1].url}
              hoverPhoto={photos[2].url}
              sku={profile.attributes.sku}
              description={`${description[0]} ${description[1]} ${description[2]}`}
              key={key}
              globalProfile={globalProfile}
              setRelleno={setRelleno}
              setGlobalProfile={setGlobalProfile}
              profileType={`${description[1]} ${description[2]}`}
              setSelectedProfileSku={setSelectedProfileSku}
            />
          );
        
      }
    });
  };

  const onBack = () => {
    return props.location.pathname.split("/line")?.[0];
  };

  return (
    <Container className="p-0">
      <Row className='justify-content-center p-0'>
        <Col className='col-12 mt-3 p-0'>
          {Loading
          ? <div>Cargando perfiles</div> 
          : profiles?.length > 0 ? <Row>{renderProfiles()}</Row> : <div>No hay perfiles disponibles para las medidas que ingresaste</div>}

          
        </Col>
        {/* <Col className='offset-lg-1 col-12 col-lg-4 mb-3'>
          <Route
            path='/client/type/:type/open'
            component={(props) => <PreviewContainer {...props} />}
          />
        </Col> */}
      </Row>
      {/* <Row>
        <Col className='col-12'>
          <BottomButtons
            onBackLink={onBack}
            buttonClassName='col-lg-3 col-6'
            onNext={() =>
              mechanized == 'NO_MECHANIZED' ? props.history.push(`${props.location.pathname}/profile/${sku}/hinge/noHinges/`) 
              : props.history.push(`${props.location.pathname}/profile/${sku}/`)
            }
            disabledNext={sku.trim() === ""}
          />
        </Col>
      </Row> */}
    </Container>
  );
};

const mapStateToProps = ({ sash, filling, greengrocerOption, mechanized, ...state }) => ({
  sash,
  fillingDescription: filling.description,
  nameColor: filling.sku,
  line: filling.line,
  greengrocerOption,
  mechanized,
  dimensions: state.dimensions,
});

const mapDispatchToProps = (dispach) => ({
  resetSash() {
    dispach({
      type: "RESET_SASH",
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Profile);

export default component;