import React, { Component } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";

class InputSelectField extends Component {
  constructor() {
    super();
    this.state = {
      select_options: [],
      value: "",
    };
  }

  handleChange = (event) => {
    this.props.changeCommuneValue(event.target.value);
    // this.props.changeBillingAddress({commune_id:event.target.value})
    const communeSelected = this.props.options.find(
      (commumeSelected) => commumeSelected.name === event.target.value
    );
    this.props.changeShippingAddress({
      commune: communeSelected.value,
    });
  };

  render() {
    let { id, name, title, disabled } = this.props;
    let value = this.props.stateSelectCommune;
    let new_value = value === undefined ? `` : value;

    let select_options = this.props.options.map((val, key) => {
      return (
        <MenuItem value={val.name} key={key}>
          {val.value}
        </MenuItem>
      );
    });

    return (
      <div className="d-flex flex-column col-12 align-items-start p-0">
        <p className="m-0 mb-2"> {title} </p>
        <FormControl variant='outlined' fullWidth margin='0'>
          <InputLabel
            shrink
            id={`${id}-label`}
            style={{
              backgroundColor: "white",
              paddingRight: "0.5rem",
              paddingLeft: "0.5rem",
            }}
          >
            {/* {title} */}
          </InputLabel>
          <Select
            labelId={`${id}-label`}
            id={id}
            className='text-left'
            name={name}
            value={new_value}
            disabled={disabled}
            onChange={this.handleChange}
          >
            <MenuItem value='' disabled />
            <MenuItem value='undefined' disabled />
            {select_options}
          </Select>
        </FormControl>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stateSelectCommune: state.stateSelectCommune,
});

const mapDispatchToProps = (dispach) => ({
  changeCommuneValue(change_state) {
    dispach({
      type: "CHANGE_STATE_SELECT_COMMUNE",
      change_state,
    });
  },
  changeShippingAddress(shipping_address) {
    dispach({
      type: "CHANGE_SHIPPING_ADDRESS_VALUE",
      payload: {
        shipping_address,
      },
    });
  },
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(InputSelectField);

export default component;
