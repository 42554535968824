export const global = {
  "api": {
    "url": process.env.REACT_APP_API_URL,
    "header": {
      "Content-Type": "application/json",
      "x-api-token": "d650a9e1-5bf1-4b36-adc8-3cff9c6e81f7"
    },
    "header_mpt_fd": {
      "x-api-token": "d650a9e1-5bf1-4b36-adc8-3cff9c6e81f7"
    },
    "header_formdata": {
      "Content-Type": "multipart/form-data",
      "x-api-token": "d650a9e1-5bf1-4b36-adc8-3cff9c6e81f7"
    }
  },
  "api_pim": {
    "url": "https://api.hbt-api.com",
    "header": {
      "Content-Type": "application/json",
      "x-api-token": "d650a9e1-5bf1-4b36-adc8-3cff9c6e81f7"
    },
    "header_mpt_fd": {
      "x-api-token": "d650a9e1-5bf1-4b36-adc8-3cff9c6e81f7"
    },
    "header_formdata": {
      "Content-Type": "multipart/form-data",
      "x-api-token": "d650a9e1-5bf1-4b36-adc8-3cff9c6e81f7"
    }
  }
}
